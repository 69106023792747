/** @jsx jsx */
import { Fragment, FC } from 'react'
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from '../../components/HelmetHelper'
import Container from '../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../components/content/Text'
import {
	THEME_TAUPE,
	THEME_WHITE,
	colors,
	media,
	THEME_PEACH,
} from '../../constants'
import Slide, {
	SLIDE_LAYOUT_MOCK_FULL_SCREEN,
	SLIDE_LAYOUT_FULL_BLEED_IMAGE,
} from '../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutCol2,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../../components/content/Layout'
import FullBleedImage from '../../components/content/FullBleedImage'
import { InlineLink, StandaloneLink } from '../../components/content/Link'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import Highlight from '../../components/content/Highlight'

import Showcase, {
	SHOWCASE_LAYOUT_1UP,
	SHOWCASE_LAYOUT_2UP,
} from '../../components/content/Showcase'
import VideoLoop, {
	VIDEO_CLIP_AUTOPLAY,
	VIDEO_CLIP_PLAYPAUSE,
} from '../../components/content/VideoLoop'

import { caseStudyLinkData } from '../../workData'

const nextPage = caseStudyLinkData.toyotard.next

type Props = {
	accentTheme: string
}

const YuiPage: FC<Props> = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			image1: file(relativePath: { eq: "yui/yui-image-01.jpg" }) {
				...ImageFluidFull
			}
			image2: file(relativePath: { eq: "yui/yui-image-02.jpg" }) {
				...ImageFluidFull
			}
			image3: file(relativePath: { eq: "yui/yui-image-03.jpg" }) {
				...ImageFluidFull
			}

			interior1: file(relativePath: { eq: "yui/interior-02.jpg" }) {
				...ImageFluidFull
			}
			interior2: file(relativePath: { eq: "yui/interior-01.jpg" }) {
				...ImageFluidFull
			}

			storyboard: file(relativePath: { eq: "yui/storyboard.png" }) {
				...ImageFluidFull
			}

			expressions: file(relativePath: { eq: "yui/expressions.png" }) {
				...ImageFluidFull
			}

			mosaic: file(relativePath: { eq: "yui/mosaic.png" }) {
				...ImageFluidFull
			}

			olympicsPoster: file(
				relativePath: { eq: "yui/yui-olympics-poster.jpg" }
			) {
				...ImageFluidFull
			}
		}
	`)

	return (
		<Fragment>
			<Helmet>
				<title>Toyota Research Institute</title>
				<meta
					content="Toyota Research Institute Case Study"
					property="og:title"
				/>
				<meta
					content="Toyota Research Institute Case Study"
					name="twitter:title"
				/>
				<meta
					property="og:description"
					content="Reimagining the future of mobility through UX orchestration and emotionally intelligent driver-AI."
				/>
				<meta
					name="description"
					content="Reimagining the future of mobility through UX orchestration and emotionally intelligent driver-AI."
				/>
				<meta
					content="https://freeassociation.com/share/toyota-yui.jpg"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/toyota-yui.jpg"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--eyebrow">Toyota Research Institute</h1>
							<p className="--headline">
								Mobility with soul,
								<br />
								AI at heart.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									Working in collaboration with Toyota’s R&D division, we
									brought to life the second generation of the LQ Concept
									vehicle and its embedded AI Agent, YUI. Together, the LQ
									concept and YUI embody Toyota’s vision for the future of
									mobility. With AI deeply integrated across the advanced LQ
									vehicle, we were able to orchestrate the transformative
									possibilities of driver-AI partnership.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Services">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled-mediumUp --col-2">
									<li>Product & UX Strategy</li>
									<li>Product Leadership</li>
									<li>UX Orchestration</li>
									<li>Conversational Design</li>
									<li>Sound & Motion Direction </li>
									<li>Visual Design System</li>
									<li>UX Prototyping</li>
									<li>Interactive Demo Direction</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<FullBleedImage fluidImageData={data.image1.childImageSharp.fluid} />
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<FullBleedImage fluidImageData={data.image2.childImageSharp.fluid} />
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<FullBleedImage fluidImageData={data.image3.childImageSharp.fluid} />
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									We developed a holistic user experience for the LQ concept
									with YUI at the heart. Outfitted with a digital instrument
									cluster, 3D HUD, spatial audio, LED illumination and haptics,
									our goal was to orchestrate a harmonious driving experience
									across all touchpoints.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Contextually Aware">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										The assistant acts as the eyes, ears and heartbeat of the
										vehicle—optimizing the driving experience for performance,
										comfort and fun.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Empathetic & Adaptive">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										YUI adapts its presence based on environment, mood, number
										of passengers in the vehicle and other multi-input factors.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Hyper-personalized">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Over time, the assistant becomes uniquely molded to each
										user—learning to anticipate routines, preferences and builds
										towards personal aspirations.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Portability Focused">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Moving fluidly with daily life, YUI brings its deep
										knowledge of users into each new Toyota vehicle, device or
										service you pair it with.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop
						width={2540}
						height={1440}
						mode={VIDEO_CLIP_PLAYPAUSE}
						hasAudio={true}
						poster={data.olympicsPoster.childImageSharp.fluid.src}
					>
						<source src="/media/yui/FA_CS_YUI_Olympics.mp4" />
					</VideoLoop>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="82.5rem">
					<Showcase
						layout={SHOWCASE_LAYOUT_2UP}
						images={[
							data.interior1.childImageSharp.fluid,
							data.interior2.childImageSharp.fluid,
						]}
					/>
				</Container>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<blockquote>
								<p className="--large">
									"With the LQ, we are proud to propose a vehicle that can
									deliver a personalized experience and build an even stronger
									bond between car and driver.”
								</p>
								<footer>
									<p className="--small">
										<strong>Daisuke Ido</strong>
										<br />
										Group Manager, Special Projects
									</p>
								</footer>
							</blockquote>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>

			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									YUI learns each individual driver’s preferences and adapts to
									serve them best as Toyota’s first Artificial Emotionally
									Intelligent (AEI) assistant. Our work invigorated a second
									generation of YUI with an updated personality and voice, a new
									visual appearance, and a broad inventory of skills.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Multi-dimension Personality">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Designed to harmonize with any user, YUI matches its
										behavior and tone with grace and purpose, inspired by the
										Japanese expression “omotenashi.”
									</p>
								</Text>
							</Highlight>
							<Highlight title="Universally Accepted">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										To foster an immediate connection with users, YUI’s voice
										was designed to transcend age and nationality while
										remaining distinctly charming and friendly.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Expressive & Clear">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										YUI combines color, gesture, tone and haptics to reinforce
										situational messaging ranging from playful discovery to the
										calm delivery of critical information.
									</p>
								</Text>
							</Highlight>
							<Highlight title="At the Ready">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Instantly accessible when invoked and appearing intuitively
										when a myrid of factors are triggered—YUI serves as a source
										of action, access and direction.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						layout={SHOWCASE_LAYOUT_1UP}
						images={[data.storyboard.childImageSharp.fluid]}
					/>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p>
								Like driving with a friend who just gets you, YUI anticipates
								needs and guides users to inspiring points-of-interest or the
								best cup of coffee on the road. As a vigilant copilot, YUI helps
								users stay alert, on track or can take the wheel should an
								emergency situation ever arise.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						layout={SHOWCASE_LAYOUT_1UP}
						images={[data.expressions.childImageSharp.fluid]}
					/>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="70rem">
					<VideoLoop
						width={2540}
						height={1440}
						mode={VIDEO_CLIP_AUTOPLAY}
						hasAudio={false}
					>
						<source src="/media/yui/FA_CS_YUI_Expressions.mp4" />
					</VideoLoop>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<Container>
						<FullBleedImage
							fluidImageData={data.mosaic.childImageSharp.fluid}
						/>
					</Container>
				</div>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<blockquote>
								<p className="--large">
									"This vision is built on an understanding that mobility goes
									beyond physical transportation to include the human need to be
									moved and engaged emotionally.”
								</p>
								<footer>
									<p className="--small">
										<strong>Daisuke Ido</strong>
										<br />
										Group Manager, Special Projects
									</p>
								</footer>
							</blockquote>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>

			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">Next</p>
							<p className="--headline">
								<StandaloneLink to={nextPage.to!}>
									{nextPage.title}
								</StandaloneLink>
							</p>
							<p className="--small">
								<InlineLink to="/work">All Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</Fragment>
	)
}

export default provideAccentTheme(YuiPage, THEME_PEACH)
